import { Fragment, useEffect, useRef } from "react";
import Navigation from "../Components/Navigation";
import { Container, Row, Col, Table } from "react-bootstrap";
import Footer from "../Components/Footer";
import integrationFlow from "../Images/integration_flow.png";
import { Link } from "react-router-dom";
import Sidebar from "../Components/Sidebar";

const Activities = () => {
  const definitionRef = useRef<HTMLDivElement>(null);
  const parametersFromActivitiesRef = useRef<HTMLDivElement>(null);

  const goTo = (ref: any) => {
    ref.current.scrollIntoView();
    ref.current.focus();
  };

  useEffect(() => {
    if (window.location.hash === "#definition" && definitionRef !== null) {
      goTo(definitionRef);
      return;
    }
    if (
      window.location.hash === "#parameters-from-activities" &&
      parametersFromActivitiesRef !== null
    ) {
      goTo(parametersFromActivitiesRef);
      return;
    }
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <Navigation />
      <Container className="mt-4">
        <Row>
          <Col xl={2} lg={3} md={4} sm={4}>
            <Sidebar current="activities" />
          </Col>
          <Col xl={10} lg={9} md={8} sm={8}>
            <h1>Activities</h1>
            <hr />
            <Row>
              <Col className="documentation">
                <p>
                  In the context of health and fitness-focused wearables,
                  activities refer to specific time-limited events during which
                  a user performs a certain physical activity. The Vivanta
                  platform captures these activities along with the
                  corresponding parameters, providing valuable insights into a
                  user's active time and physical performance.
                </p>
                <div ref={definitionRef}></div>
                <h3>Definition</h3>
                <p>
                  Wearable devices offer a wide range of different activities,
                  each with its own set of parameters that can be tracked and
                  analyzed. Vivanta recognizes the significant benefits of
                  tracking day-to-day activities and offers a curated list of
                  such activities to its users. These activities range from
                  simple tasks like walking and sleeping to more complex
                  activities like cycling, swimming, running and strength. By
                  tracking these activities, users can gain insights into their
                  physical performance, set goals, and tailor their fitness
                  routines to their individual needs.
                </p>
                <p>
                  At Vivanta, we believe in providing our customers with a
                  comprehensive range of activities to choose from. To that end,
                  we have classified our activities into six distinct categories
                  that cater to different interests and skill levels:
                </p>
                <ul>
                  <li>
                    <strong>Running:</strong> All running related activities
                    (treadmill running, marathon, etc.)
                  </li>
                  <li>
                    <strong>Cycling:</strong> All cycling related activities
                    (indoor cycling, duathlon cycling, etc.)
                  </li>
                  <li>
                    <strong>Swimming:</strong> All swimming related activities
                    (indoor swimming, open water swimming, etc.)
                  </li>
                  <li>
                    <strong>Walking:</strong> All walking related activities
                    (hiking, outdoor walking, etc.)
                  </li>
                  <li>
                    <strong>Sleeping:</strong> Daily sleeping activities.
                  </li>
                  <li>
                    <strong>Mindfulness:</strong> All mindfulness related
                    activities (yoga, breathwork, etc.)
                  </li>
                  <li>
                    <strong>Strength:</strong> All strength related activities
                    (weightlifting, bodyweight training, etc.)
                  </li>
                </ul>
                <div ref={parametersFromActivitiesRef}></div>
                <h3>Parameters from Activities</h3>
                <p>
                  From these categories, you can obtain a wide range of health
                  parameters that are related and depend on each individual
                  activity. The complete list of activity parameters by brand is
                  the following:
                </p>
                <Table striped bordered className="mt-4 mb-4">
                  <thead>
                    <tr>
                      <th>ACTIVITY NAME</th>
                      <th>FITBIT</th>
                      <th>GARMIN</th>
                      <th>POLAR</th>
                      <th>WITHINGS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>
                        Running
                        <br />
                        <em>running</em>
                      </th>
                      <td>
                        Active Calories
                        <br />
                        Average HR
                        <br />
                        Distance
                        <br />
                        Duration
                        <br />
                        Steps
                      </td>
                      <td>
                        Active Calories
                        <br />
                        Average HR
                        <br />
                        Distance
                        <br />
                        Duration
                        <br />
                        Steps
                      </td>
                      <td>
                        Active Calories
                        <br />
                        Average HR
                        <br />
                        Distance
                        <br />
                        Duration
                      </td>
                      <td>
                        Active Calories
                        <br />
                        Distance
                        <br />
                        Steps
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Cycling
                        <br />
                        <em>cycling</em>
                      </th>
                      <td>
                        Active Calories
                        <br />
                        Average HR
                        <br />
                        Distance
                        <br />
                        Duration
                      </td>
                      <td>
                        Active Calories
                        <br />
                        Average HR
                        <br />
                        Distance
                        <br />
                        Duration
                      </td>
                      <td>
                        Active Calories
                        <br />
                        Average HR
                        <br />
                        Distance
                        <br />
                        Duration
                      </td>
                      <td>
                        Active Calories
                        <br />
                        Distance
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Swimming
                        <br />
                        <em>swimming</em>
                      </th>
                      <td>
                        Active Calories
                        <br />
                        Distance
                        <br />
                        Duration
                      </td>
                      <td>
                        Active Calories
                        <br />
                        Average HR
                        <br />
                        Distance
                        <br />
                        Duration
                      </td>
                      <td>
                        Active Calories
                        <br />
                        Average HR
                        <br />
                        Duration
                      </td>
                      <td>Active calories</td>
                    </tr>
                    <tr>
                      <th>
                        Walking
                        <br />
                        <em>walking</em>
                      </th>
                      <td>
                        Active Calories
                        <br />
                        Average HR
                        <br />
                        Duration
                        <br />
                        Steps
                      </td>
                      <td>
                        Active Calories
                        <br />
                        Average HR
                        <br />
                        Distance
                        <br />
                        Duration
                        <br />
                        Steps
                      </td>
                      <td>
                        Active Calories
                        <br />
                        Average HR
                        <br />
                        Distance
                        <br />
                        Duration
                      </td>
                      <td>
                        Active Calories
                        <br />
                        Average HR
                        <br />
                        Distance
                        <br />
                        Steps
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Sleeping
                        <br />
                        <em>sleeping</em>
                      </th>
                      <td>
                        Sleep start time
                        <br />
                        Sleep end time
                        <br />
                        Deep sleep duration
                        <br />
                        Light sleep duration
                        <br />
                        REM sleep duration
                        <br />
                        Total sleep duration
                      </td>
                      <td>
                        Sleep start time
                        <br />
                        Sleep end time
                        <br />
                        Deep sleep duration
                        <br />
                        Light sleep duration
                        <br />
                        REM sleep duration
                        <br />
                        Total sleep duration
                      </td>
                      <td>
                        Sleep start time
                        <br />
                        Sleep end time
                        <br />
                        Deep sleep duration
                        <br />
                        Light sleep duration
                        <br />
                        REM sleep duration
                        <br />
                        Total sleep duration
                      </td>
                      <td>
                        Sleep start time
                        <br />
                        Sleep end time
                        <br />
                        Deep sleep duration
                        <br />
                        Light sleep duration
                        <br />
                        REM sleep duration
                        <br />
                        Total sleep duration
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Mindfulness
                        <br />
                        <em>mindfulness</em>
                      </th>
                      <td>
                        Active Calories
                        <br />
                        Average HR
                        <br />
                        Duration
                      </td>
                      <td>
                        Active Calories
                        <br />
                        Average HR
                        <br />
                        Duration
                      </td>
                      <td>
                        Active Calories
                        <br />
                        Average HR
                        <br />
                        Duration
                      </td>
                      <td>Active Calories</td>
                    </tr>
                    <tr>
                      <th>
                        Strength
                        <br />
                        <em>strength</em>
                      </th>
                      <td>
                        Active Calories
                        <br />
                        Average HR
                        <br />
                        Duration
                      </td>
                      <td>
                        Active Calories
                        <br />
                        Average HR
                        <br />
                        Duration
                      </td>
                      <td>
                        Active Calories
                        <br />
                        Average HR
                        <br />
                        Duration
                      </td>
                      <td>
                        Active Calories
                        <br />
                        Average HR
                        <br />
                        Duration
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <div className="d-flex justify-content-between mt-4">
                  <Link to={"/parameters"} className="btn btn-light">
                    &larr; Parameters
                  </Link>
                  <Link to={"/intraday"} className="btn btn-light">
                    Intraday &rarr;
                  </Link>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </Fragment>
  );
};

export default Activities;

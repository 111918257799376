import { Fragment, useEffect } from "react";
import Navigation from "../Components/Navigation";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const Box = (props: any) => {
    return (
      <Col lg={4} md={6} sm={12} className="pb-4">
        <Card>
          <Card.Body>
            <Card.Title className="mb-3 vivanta-color-1">
              <Link style={{ textDecoration: "none" }} to={props.link ?? "/"}>
                {props.title}
              </Link>
            </Card.Title>
            <hr />
            {props.children}
          </Card.Body>
          {props.footer && (
            <Card.Footer className="text-muted">{props.footer}</Card.Footer>
          )}
        </Card>
      </Col>
    );
  };

  return (
    <Fragment>
      <Navigation />
      <Container className="mt-4">
        <Row>
          <Col>
            <h1>Vivanta Docs 🚀</h1>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col className="text-right">
            <h4 style={{ fontWeight: 300 }}>
              Help to add health data to your product
            </h4>
          </Col>
        </Row>
        <Row className="mt-4 pt-4">
          <Box title="Introduction" link={"/introduction"}>
            <div className="box-body">
              <p>
                <Link to={"/introduction#overview"}>Overview</Link>
              </p>
              <p>
                <Link to={"/introduction#key-concepts"}>Key concepts</Link>
              </p>
              <p>
                <Link to={"/introduction#next-steps"}>Next steps</Link>
              </p>
            </div>
          </Box>
          <Box title="Quickstart" link={"/quick-start"}>
            <div className="box-body">
              <p>
                <Link to={"/quick-start#connect"}>Vivanta Connect</Link>
              </p>
              <p>
                <Link to={"/quick-start#api"}>API</Link>
              </p>
              <p>
                <Link to={"/quick-start#webhooks"}>Webhooks</Link>
              </p>
            </div>
          </Box>
          <Box title="Vivanta Connect" link={"/connect"}>
            <div className="box-body">
              <p>
                <Link to={"/connect"}>How to integrate</Link>
              </p>
              <p>
                <Link to={"/apple-health"}>Apple Health</Link>
              </p>
              <p>
                <Link to={"/google-health"}>Google Health Connect</Link>
              </p>
            </div>
          </Box>
          <Box title="API overview" link={"/api"}>
            <div className="box-body">
              <p>
                <Link to={"/api"}>Get all Users</Link>
              </p>
              <p>
                <Link to={"/api"}>Get specific User Parameters</Link>
              </p>
              <p>
                <Link to={"/api"}>View all...</Link>
              </p>
            </div>
          </Box>
          <Box title="Webhooks" link={"/webhooks"}>
            <div className="box-body">
              <p>
                <Link to={"/webhooks#connected-users"}>Users Connected</Link>
              </p>
              <p>
                <Link to={"/webhooks#updated-parameters"}>
                  Parameters Updated
                </Link>
              </p>
              <p>&nbsp;</p>
            </div>
          </Box>
          <Box title="Parameters" link={"/parameters"}>
            <div className="box-body">
              <p>
                <Link to={"/parameters#definition"}>Definition</Link>
              </p>
              <p>
                <Link to={"/parameters#available"}>Parameters available</Link>
              </p>
              <p>
                <Link to={"/parameters#unit-conversion-simplified"}>
                  Unit conversion simplified
                </Link>
              </p>
            </div>
          </Box>
          <Box title="Activities" link={"/activities"}>
            <div className="box-body">
              <p>
                <Link to={"/activities#definition"}>Definition</Link>
              </p>
              <p>
                <Link to={"/activities#parameters-from-activities"}>
                  Parameters from Activities
                </Link>
              </p>
              <p>&nbsp;</p>
            </div>
          </Box>
          <Box title="Intraday" link={"/intraday"}>
            <div className="box-body">
              <p>
                <Link to={"/intraday#introduction"}>Introduction</Link>
              </p>
              <p>
                <Link to={"/intraday#workflow"}>Workflow</Link>
              </p>
              <p>&nbsp;</p>
            </div>
          </Box>
          <Box title="Embedded Graphs" link={"/embedded-graphs"}>
            <div className="box-body">
              <p>
                <Link to={"/embedded-graphs#definition"}>Definition</Link>
              </p>
              <p>
                <Link to={"/embedded-graphs#activation-and-retention"}>
                  Activation and Retention
                </Link>
              </p>
              <p>
                <Link to={"/embedded-graphs#graphs-portfolio"}>
                  Portfolio of Graphs
                </Link>
              </p>
            </div>
          </Box>
        </Row>
      </Container>
      <Footer />
    </Fragment>
  );
};

export default Home;

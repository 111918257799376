import { Fragment, useEffect, useRef } from "react";
import Navigation from "../Components/Navigation";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../Components/Footer";
import "swagger-ui-react/swagger-ui.css";
import { Link } from "react-router-dom";
import Sidebar from "../Components/Sidebar";

const GoogleHealthConnect = () => {
  const requirementsRef = useRef<HTMLDivElement>(null);

  const goTo = (ref: any) => {
    ref.current.scrollIntoView();
    ref.current.focus();
  };

  useEffect(() => {
    if (window.location.hash === "#overview" && requirementsRef !== null) {
      goTo(requirementsRef);
      return;
    }
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <Navigation />
      <Container className="mt-4">
        <Row>
          <Col xl={2} lg={3} md={4} sm={4}>
            <Sidebar current="google-health-connect" />
          </Col>
          <Col xl={10} lg={9} md={8} sm={8}>
            <h1>Google Health Connect</h1>
            <hr />
            <Row>
              <Col className="documentation">
                <p>
                  You can easily integrate Google Health Connect using Vivanta
                  to collect and analyze users&#39; health data. With this
                  integration, you can provide personalized health insights and
                  recommendations to your users based on their health metrics
                  from Google Health Connect. To add Google Health Connect to
                  your Android app, follow these steps.
                </p>
                <div ref={requirementsRef}></div>
                <h3>Requirements</h3>
                <ol>
                  <li>You have an Android app</li>
                  <li>
                    Your application is compatible with the compiled SDK 34+
                  </li>
                  <li>
                    Your application is published or will be available in the
                    Google Play Store*
                  </li>
                  <li>
                    <p>
                      You have identified the specific Google Health parameters
                      you will use
                    </p>
                  </li>
                  <li>
                    <p>
                      Google{" "}
                      <a href="https://developer.android.com/health-and-fitness/guides/health-connect/publish/request-access">
                        will validate your published app
                      </a>{" "}
                      before providing productive access to health parameters.
                      The development access is unrestricted.
                    </p>
                  </li>
                </ol>
                <p>
                  An<strong> Android app is mandatory</strong> for integrating
                  with Google Health Connect
                </p>
                <p>
                  Given the sensitive nature of health information, Google has
                  implemented stringent privacy and security measures.
                </p>
                <blockquote>
                  <p>
                    The Google Health Connect data is only kept locally on the
                    user’s device. For security, the Google Health Connect data
                    is blocked for background services. The Google Health
                    Connect data store can only be accessed by an authorized
                    app.
                  </p>
                </blockquote>
                <h3>Converting my app into a Google Health Connect App</h3>
                <p>In your Android Studio project:</p>
                <ol>
                  <li>Open your app project</li>
                  <li>
                    <p>
                      Go to the <code>AndroidManifest.xml</code> file and add
                      the following code inside the <code>root</code> node:
                    </p>
                    <pre>
                      <code className="lang-xml">
                        {" "}
                        <span className="hljs-tag">
                          &lt;<span className="hljs-name">queries</span>&gt;
                        </span>
                        <span className="hljs-tag">
                          &lt;<span className="hljs-name">package</span>{" "}
                          <span className="hljs-attr">android:name</span>=
                          <span className="hljs-string">
                            "com.google.android.apps.healthdata"
                          </span>{" "}
                          /&gt;
                        </span>
                        <span className="hljs-tag">
                          &lt;<span className="hljs-name">package</span>{" "}
                          <span className="hljs-attr">android:name</span>=
                          <span className="hljs-string">
                            "com.google.android.apps.fitness"
                          </span>{" "}
                          /&gt;
                        </span>
                        <span className="hljs-tag">
                          &lt;/<span className="hljs-name">queries</span>&gt;
                        </span>
                      </code>
                    </pre>
                  </li>
                  <li>
                    <p>
                      Add the following code in your main{" "}
                      <code>&lt;activity&gt;</code> node:
                    </p>
                    <pre>
                      <code className="lang-xml">
                        {" "}
                        <span className="hljs-tag">
                          &lt;<span className="hljs-name">intent-filter</span>
                          &gt;
                        </span>
                        <span className="hljs-tag">
                          &lt;<span className="hljs-name">action</span>{" "}
                          <span className="hljs-attr">android:name</span>=
                          <span className="hljs-string">
                            "androidx.health.ACTION_SHOW_PERMISSIONS_RATIONALE"
                          </span>{" "}
                          /&gt;
                        </span>
                        <span className="hljs-tag">
                          &lt;/<span className="hljs-name">intent-filter</span>
                          &gt;
                        </span>
                      </code>
                    </pre>
                  </li>
                  <li>
                    <p>
                      Add at least one of the permissions your app will have
                      access to in your AndroidManifest.xml inside the root
                      node. The complete list of permissions is in{" "}
                      <a href="https://developer.android.com/health-and-fitness/guides/health-connect/plan/data-types#alpha10">
                        this link
                      </a>
                      . For example:
                    </p>
                    <pre>
                      <code className="lang-xml">
                        {" "}
                        &lt;uses-permission android:
                        <span className="hljs-built_in">name</span>=
                        <span className="hljs-string">
                          "android.permission.health.READ_STEPS"
                        </span>
                        /&gt;
                      </code>
                    </pre>
                  </li>
                  <li>
                    <p>
                      In your <code>gradle.properties</code> file, replace these
                      values:
                    </p>
                    <pre>
                      <code className="lang-groovy">
                        {" "}
                        org.gradle.
                        <span className="hljs-attr">jvmargs=-Xmx1536M</span>
                        android.
                        <span className="hljs-attr">enableJetifier=true</span>
                        android.
                        <span className="hljs-attr">useAndroidX=true</span>
                      </code>
                    </pre>
                  </li>
                </ol>
                <h3 id="integrating-google-health-connect-via-vivanta-connect">
                  Integrating Google Health Connect via Vivanta Connect
                </h3>
                <ul>
                  <li>
                    To start using Google Health Connect, Vivanta will provide
                    you with customized libraries that you will need to import
                    into your Android project. It&#39;s important to note that
                    these libraries are tailored to your specific product needs.
                  </li>
                  <li>
                    Once you have imported the libraries, you must choose the
                    place in your app to launch the Vivanta Connect native
                    plugin.
                  </li>
                  <li>
                    Additionally, you must identify the place in your app where
                    Vivanta Connect can obtain Google Health Connect parameters.
                    Only your app can access these parameters, so it&#39;s
                    essential to define the periodicity of access.
                  </li>
                  <li>
                    Finally, the analysis and availability of the parameters
                    into the Vivanta API and Webhooks will be automatically
                    processed by the Vivanta Connect plugin.
                  </li>
                </ul>
                <p>
                  If you have questions about integrating Vivanta Connect,
                  please contact us to schedule a call.
                </p>
              </Col>
            </Row>

            <Row>
              <div className="d-flex justify-content-between mt-4">
                <Link to={"/apple-health"} className="btn btn-light">
                  &larr; Apple Health
                </Link>
                <Link to={"/api"} className="btn btn-light">
                  API &rarr;
                </Link>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </Fragment>
  );
};

export default GoogleHealthConnect;

import { Fragment, useEffect, useRef } from "react";
import Navigation from "../Components/Navigation";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import Sidebar from "../Components/Sidebar";
import { CodeBlock } from "react-code-blocks";

const Intraday = () => {
  const introductionRef = useRef<HTMLDivElement>(null);
  const workflowRef = useRef<HTMLDivElement>(null);

  const goTo = (ref: any) => {
    ref.current.scrollIntoView();
    ref.current.focus();
  };

  useEffect(() => {
    if (window.location.hash === "#introduction" && introductionRef !== null) {
      goTo(introductionRef);
      return;
    }
    if (window.location.hash === "#workflow" && workflowRef !== null) {
      goTo(workflowRef);
      return;
    }
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <Navigation />
      <Container className="mt-4">
        <Row>
          <Col xl={2} lg={3} md={4} sm={4}>
            <Sidebar current="intraday" />
          </Col>
          <Col xl={10} lg={9} md={8} sm={8}>
            <h1>Intraday</h1>
            <hr />
            <Row>
              <Col className="documentation">
                <p>
                  Vivanta supports requests for Intraday Data from Brands. This
                  means we can obtain granular information from a single
                  day&apos;s Parameters (p.e. heart rate every minute from
                  Fitbit devices).
                </p>
                <div ref={introductionRef}></div>
                <h3>Introduction</h3>
                <p>
                  This information is generally not delivered by the Brands via
                  Webhooks due to the size of the files, that's why, when
                  Vivanta receives a valid User request to get Intraday Data, we
                  deliver an immediate successful response and proceed to obtain
                  and process the Data. When the User’s Data is ready, we send a
                  Webhook to your predefined `intraday_webhook` URL (
                  <a href="https://docs.vivanta.io/quick-start#webhooks">
                    see about webhooks
                  </a>
                  ). This document describes this process.
                </p>
                <div ref={workflowRef}></div>
                <h3>Workflow</h3>
                <h5>Getting a UUID for Intraday Resource download *</h5>
                <ol>
                  <li>
                    We receive a request from the Client asking for Intraday
                    Data for a specific date and Brand
                  </li>
                  <li>
                    Upon request validation, we immediately respond with a 200
                    HTTP Status
                  </li>
                  <li>
                    Vivanta communicates with the Brand API and gets the
                    Intraday Data. After that, the information is processed and
                  </li>
                  <li>
                    We deliver a Webhook which contains a UUID to start the next
                    process. An HTTP Request is sent using the UUID from step 4
                    in the last workflow. Example response:
                  </li>
                </ol>
                <CodeBlock
                  text={`{
    "uuid": "857d7e74-488f-4c3e-84a7-52e47d589201",
    "parameter": "heart",
    "externalId": "externalId",
    "id": 1315,
    "date": "2023-12-13"
}`}
                  numberLines={true}
                  language={"javascript"}
                  theme={"dracula"}
                  wrapLines
                />
                <ol start={5}>
                  <li>
                    * If you are working with Apple Connect or Health Connect,
                    your process ends here, since you will receive a Webhook
                    with your User’s Intraday Data
                  </li>
                </ol>
                <CodeBlock
                  text={`{
    "event": "intraday",
    "externalId": "jesus123",
    "brandId": "6",
    "version": "1",
    "parameter": "heart",
    "data": [
        {
            "time": "09:15:27",
            "value": 81
        },
        {
            "time": "09:25:42",
            "value": 84
        }
    ]
}`}
                  numberLines={true}
                  language={"javascript"}
                  theme={"dracula"}
                  wrapLines
                />
                <h5>Retrieving an Intraday Resource *</h5>
                <p>
                  * Skip this in Apple Health and Google Health Connect requests
                </p>
                <ol>
                  <li>
                    The Client sends an HTTP request to download an Intraday
                    Resource using the UUID from step 4 of the previous workflow
                    (the endpoint is{" "}
                    <strong>
                      /users/&#123;id&#125;/intraday-parameters/download
                    </strong>
                    ). See <Link to={"/api"}>the API reference</Link>.
                  </li>
                  <li>
                    Vivanta responds with an HTTP response containing a
                    standardized JSON with Intraday Data. A portion of a
                    standardized Intraday JSON is shown here:
                  </li>
                </ol>
                <CodeBlock
                  text={`[
    {
        "time": "09:15:27",
        "value": 81
    },
    {
        "time": "09:25:42",
        "value": 84
    },
    {
        "time": "09:28:10",
        "value": 89
    },
    {
        "time": "09:32:01",
        "value": 76
    }
]`}
                  numberLines={true}
                  language={"javascript"}
                  theme={"dracula"}
                  wrapLines
                />
                <div className="d-flex justify-content-between mt-4">
                  <Link to={"/activities"} className="btn btn-light">
                    &larr; Activities
                  </Link>
                  <Link to={"/embedded-graphs"} className="btn btn-light">
                    Embedded Graphs &rarr;
                  </Link>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </Fragment>
  );
};

export default Intraday;

import { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import LogoSvg from "../Images/logo_vivanta_black.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="bg-light">
      <Container className="mt-4 pt-4 pb-3">
        <Row className="mt-4 mb-4">
          <Col>
            <Link to="/" className="text-white">
              <img
                src={LogoSvg}
                height={30}
                alt="Vivanta"
                style={{ color: "white" }}
                className="d-inline-block align-top vivanta-header-logo"
              />{" "}
            </Link>
          </Col>
          <Col className="d-flex justify-content-end">
            <p>2023 &copy; Vivanta Inc.</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;

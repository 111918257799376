import { Fragment, useEffect, useRef, useState } from "react";
import Navigation from "../Components/Navigation";
import { Container, Row, Col, Table } from "react-bootstrap";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import Sidebar from "../Components/Sidebar";

const EmbeddedGraphs = () => {
  const definitionRef = useRef<HTMLDivElement>(null);
  const activationAndRetentionRef = useRef<HTMLDivElement>(null);
  const effortlessIntegrationRef = useRef<HTMLDivElement>(null);
  const graphsPortfolioRef = useRef<HTMLDivElement>(null);
  const [graphLink, setGraphLink] = useState("");

  const goTo = (ref: any) => {
    ref.current.scrollIntoView();
    ref.current.focus();
  };

  useEffect(() => {
    if (window.location.hash === "#definition" && definitionRef !== null) {
      goTo(definitionRef);
      return;
    }
    if (
      window.location.hash === "#activation-and-retention" &&
      activationAndRetentionRef !== null
    ) {
      goTo(activationAndRetentionRef);
      return;
    }
    if (
      window.location.hash === "#effortless-integration" &&
      effortlessIntegrationRef !== null
    ) {
      goTo(effortlessIntegrationRef);
      return;
    }
    if (
      window.location.hash === "#graphs-portfolio" &&
      graphsPortfolioRef !== null
    ) {
      goTo(graphsPortfolioRef);
      return;
    }
    window.scrollTo(0, 0);
  }, []);

  const showGraph = (graphName: string) => {
    if (graphName !== "") {
      setGraphLink(graphLink === graphName ? "" : graphName);
      return;
    }
    setGraphLink("");
    return;
  };

  return (
    <Fragment>
      <Navigation />
      <Container className="mt-4">
        <Row>
          <Col xl={2} lg={3} md={4} sm={4}>
            <Sidebar current="graphs" />
          </Col>
          <Col xl={10} lg={9} md={8} sm={8}>
            <h1>Embedded Graphs</h1>
            <hr />
            <Row>
              <Col className="documentation">
                <p>
                  The data provided via health devices is highly relevant for
                  decision-making in modern health related domains. This data,
                  when grouped and analyzed over a specific period of time,
                  offers valuable insights into:
                </p>
                <ol>
                  <li>
                    <strong>Activity Level:</strong> The data can indicate the
                    level of physical activity or movement throughout the day,
                    providing depth into periods of low activity or sedentary
                    behavior.
                  </li>
                  <li>
                    <strong>Sleep Quality:</strong> By analyzing sleep-related
                    data, such as duration and sleep patterns, valuable insights
                    can be gained regarding the quality of sleep, including
                    sleep efficiency, disturbances, and overall sleep health.
                  </li>
                  <li>
                    <strong>Activity Recurrence:</strong> The data can help
                    track the frequency and consistency of physical activity,
                    understand individuals' exercise habits, and identify
                    irregularities or changes over time.
                  </li>
                  <li>
                    <strong>Irregular Heart Rate Fluctuations:</strong> By
                    monitoring heart rate data, you can detect variations in
                    heart rhythm that deviate from the norm. These fluctuations,
                    such as irregular heartbeats or abnormal patterns, may
                    require further investigation or attention.
                  </li>
                </ol>
                <p>
                  When data is represented graphically, the analysis becomes
                  more straightforward and efficient. Visualizing the data
                  through graphs or charts aids in identifying patterns, trends,
                  and anomalies, facilitating a comprehensive understanding of
                  the individual's health status and enabling informed
                  decision-making.
                </p>
                <div ref={definitionRef}></div>
                <h3>Definition</h3>
                <p>
                  Embedded Graphs are HTML visual elements that can be accessed
                  to analyze relevant information about the health parameters
                  that Vivanta has access to.
                </p>
                <p>The platform provides two types of Embedded Graphs:</p>
                <ol>
                  <li>
                    <strong>User Embedded Graphs:</strong> These graphs contain
                    specific information for indvidual users and are recommended
                    to be displayed within mobile or web applications.
                  </li>
                  <li>
                    <strong>Client Embedded Graphs:</strong> These graphs offer
                    a comprehensive view of health data across a specific
                    population of users. These graphs allow for a centralized
                    and thorough understanding of the health parameters
                    collected from your users. Graphs can be seamlessly
                    integrated into dashboards for your back office or clients.
                  </li>
                </ol>
                <div ref={activationAndRetentionRef}></div>
                <h3>Activation and Retention</h3>
                <p>
                  Integrating Embedded Graphs into your product can increase the
                  percentage of Monthly Active Users (MAUs) up to 10x since
                  these graphs are constantly updated whenever Vivanta receives
                  updated parameters. Your users will be able to view up-to-date
                  and relevant health information.
                </p>
                <div ref={effortlessIntegrationRef}></div>
                <h3>Effortless Integration</h3>
                <p>
                  The process to integrate Embedded Graphs into your product
                  consists of 3 simple steps:
                </p>
                <ol>
                  <li>
                    Define the location where you want to display the
                    information to your users. The graph can be shown in a web
                    URL, allowing you to launch a WebView for mobile
                    applications or in a new window for web applications.
                  </li>
                  <li>
                    Send a Request to the Vivanta API, specifying the user and
                    the type of graph you wish to display.
                  </li>
                  <li>
                    Retrieve the link provided by the Vivanta API in the
                    Response. This URL is ready for visualization and does not
                    require any additional authentication.
                  </li>
                </ol>
                <div ref={graphsPortfolioRef}></div>
                <h3>Graphs Portfolio</h3>
                <p>
                  Vivanta has a broad portfolio of graphs, all you have to do is
                  select the one's you want to display to your users and clients
                  and listo!
                </p>
                <Table striped bordered className="mt-4 mb-4">
                  <thead>
                    <tr>
                      <th>GRAPH NAME</th>
                      <th>OBJECTIVE</th>
                      <th>EXAMPLE</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        Activity
                        <br />
                        <em>steps_by_week</em>
                      </td>
                      <td>
                        Get the User’s activity status based on the last 7 days
                        of total steps & the activity distribution based on 14
                        days of total steps
                      </td>
                      <td className="text-center">
                        <button
                          className={`btn btn-${
                            graphLink === "steps_by_week"
                              ? "light"
                              : "primary text-white"
                          }`}
                          onClick={() => showGraph("steps_by_week")}
                        >
                          {graphLink === "steps_by_week" ? "Hide" : "Show"}
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Active time
                        <br />
                        <em>activities_by_week</em>
                      </td>
                      <td>
                        Get the User’s active time score based on the
                        recommendation of 180 minutes of activity per week & the
                        active time distribution based on the last 7 days of
                        activities duration
                      </td>
                      <td className="text-center">
                        <button
                          className={`btn btn-${
                            graphLink === "activities_by_week"
                              ? "light"
                              : "primary text-white"
                          }`}
                          onClick={() => showGraph("activities_by_week")}
                        >
                          {graphLink === "activities_by_week" ? "Hide" : "Show"}
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Sleep status
                        <br />
                        <em>sleep_by_week</em>
                      </td>
                      <td>
                        Get the User’s sleep score based on the last 3 days of
                        total sleep duration & the sleep distribution based on
                        the last 14 days of total sleep duration
                      </td>
                      <td className="text-center">
                        <button
                          className={`btn btn-${
                            graphLink === "sleeps_by_week"
                              ? "light"
                              : "primary text-white"
                          }`}
                          onClick={() => showGraph("sleeps_by_week")}
                        >
                          {graphLink === "sleeps_by_week" ? "Hide" : "Show"}
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Calories
                        <br />
                        <em>calories_by_week</em>
                      </td>
                      <td>
                        Get the User’s calories burned based on the last 7 days
                        of total calories
                      </td>
                      <td className="text-center">
                        <button
                          className={`btn btn-${
                            graphLink === "calories_by_week"
                              ? "light"
                              : "primary text-white"
                          }`}
                          onClick={() => showGraph("calories_by_week")}
                        >
                          {graphLink === "calories_by_week" ? "Hide" : "Show"}
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Average heart rate
                        <br />
                        <em>avg_heart_rate_by_week</em>
                      </td>
                      <td>
                        Get the User’s average heart rate based on the last 7
                        days of the heart rate distribution
                      </td>
                      <td className="text-center">
                        <button
                          className={`btn btn-${
                            graphLink === "avg_heart_rate_by_week"
                              ? "light"
                              : "primary text-white"
                          }`}
                          onClick={() => showGraph("avg_heart_rate_by_week")}
                        >
                          {graphLink === "avg_heart_rate_by_week"
                            ? "Hide"
                            : "Show"}
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <iframe
                  className={graphLink === "steps_by_week" ? "" : "d-none"}
                  title="Steps by week"
                  src="https://portal.vivanta.io/graphs/steps-by-week/5c730a52-d40f-4714-b4b9-787935b5d5e0"
                  style={{ overflow: "hidden", height: "700px", width: "100%" }}
                  height="700"
                  width="100%"
                ></iframe>
                <iframe
                  className={graphLink === "activities_by_week" ? "" : "d-none"}
                  title="Activities by week"
                  src="https://portal.vivanta.io/graphs/activities-by-week/3c799cdf-f8d8-46df-8042-9d189b62f1dc"
                  style={{ overflow: "hidden", height: "700px", width: "100%" }}
                  height="700"
                  width="100%"
                ></iframe>
                <iframe
                  className={graphLink === "sleeps_by_week" ? "" : "d-none"}
                  title="Sleep by week"
                  src="https://portal.vivanta.io/graphs/sleeps-by-week/0879c3d5-6d99-4d61-9972-7bd3e272860f"
                  style={{ overflow: "hidden", height: "700px", width: "100%" }}
                  height="700"
                  width="100%"
                ></iframe>
                <iframe
                  className={graphLink === "calories_by_week" ? "" : "d-none"}
                  title="Sleep by week"
                  src="https://portal.vivanta.io/graphs/calories-by-week/b8e89bae-8768-4253-be08-467bde8cc959"
                  style={{ overflow: "hidden", height: "700px", width: "100%" }}
                  height="700"
                  width="100%"
                ></iframe>
                <iframe
                  className={
                    graphLink === "avg_heart_rate_by_week" ? "" : "d-none"
                  }
                  title="Sleep by week"
                  src="https://portal.vivanta.io/graphs/avg-heart-rate-by-week/8775d56a-a338-4db2-acac-d91777017039"
                  style={{ overflow: "hidden", height: "700px", width: "100%" }}
                  height="700"
                  width="100%"
                ></iframe>
                <div className="d-flex justify-content-start mt-4">
                  <Link to={"/activities"} className="btn btn-light">
                    &larr; Activities
                  </Link>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </Fragment>
  );
};

export default EmbeddedGraphs;

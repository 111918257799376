import { Fragment, useEffect, useRef } from "react";
import Navigation from "../Components/Navigation";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../Components/Footer";
import integrationFlow from "../Images/integration_flow.png";
import { Link } from "react-router-dom";
import Sidebar from "../Components/Sidebar";

const Introduction = () => {
  const overviewRef = useRef<HTMLDivElement>(null);
  const keyConceptsRef = useRef<HTMLDivElement>(null);
  const nextStepsRef = useRef<HTMLDivElement>(null);

  const goTo = (ref: any) => {
    ref.current.scrollIntoView();
    ref.current.focus();
  };

  useEffect(() => {
    if (window.location.hash === "#overview" && overviewRef !== null) {
      goTo(overviewRef);
      return;
    }
    if (window.location.hash === "#key-concepts" && keyConceptsRef !== null) {
      goTo(keyConceptsRef);
      return;
    }
    if (window.location.hash === "#next-steps" && nextStepsRef !== null) {
      goTo(nextStepsRef);
      return;
    }
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <Navigation />
      <Container className="mt-4">
        <Row>
          <Col xl={2} lg={3} md={4} sm={4}>
            <Sidebar current="introduction" />
          </Col>
          <Col xl={10} lg={9} md={8} sm={8}>
            <h1>Introduction</h1>
            <hr />
            <Row>
              <Col className="documentation">
                <div ref={overviewRef}></div>
                <p>
                  Vivanta is a complete platform to integrate with major digital
                  health device manufacturers easily. Our robust technological
                  solution enables companies to complete an integration process
                  and connect with multiple brands in{" "}
                  <strong>less than 14 days</strong>.
                </p>
                <p>
                  We provide tools, code samples, libraries, interfaces, APIs,
                  Webhooks, and advice that helps finish integrations in record
                  time.
                </p>
                <div ref={keyConceptsRef}></div>
                <h3>Key concepts</h3>
                <p>
                  Some of the concepts below may seem very familiar to you.
                  You'll become familiar with them by reviewing them, and
                  following the documentation shown here will be a breeze.
                </p>
                <h5>User</h5>
                <p>
                  A User is a person who uses your application/platform/product;
                  in other words, a customer or patient. It's the entity that
                  uses a device that authorizes accessing its health parameters.
                </p>
                <h5>Connected User</h5>
                <p>
                  A Connected User is one who's provided access to its Health
                  Parameters.
                </p>
                <h5>Vivanta Connect</h5>
                <p>
                  Is a front-end tool that integrates into your product by
                  adding a few lines of code. Its function is to guide the User
                  in the connection flow.
                </p>
                <h5>Brand</h5>
                <p>
                  A Brand is a manufacturer of digital health devices available
                  in Vivanta. Brands are sources of digital health parameters.
                </p>
                <h5>Vivanta API</h5>
                <p>
                  Refers to the technology that Vivanta provides Clients to
                  access digital health parameters of Connected Users. The API
                  unifies all the available Brands, abstracting the Client from
                  carrying out individual processes for each.
                </p>
                <h5>Webhook</h5>
                <p>
                  Webhooks notify clients immediately about User status changes,
                  such as authorizations or daily updates to the values of their
                  digital health parameters. Webhooks allow Clients to store
                  Users' data for queries and analysis.
                </p>
                <h5>Client</h5>
                <p>
                  A Client owns the product that integrates with the Vivanta
                  Platform. Clients want to access Users' data using the Vivanta
                  Widget and tools.
                </p>
                <h5>Certified Client</h5>
                <p>
                  We consider a Client to be a Certified Client when it has
                  carried out an integration process validated by Vivanta. In
                  such a process, we ensure the correct functionality of the
                  Clients' products in conjunction with the Vivanta Platform.
                </p>
                <h5>Parameter</h5>
                <p>
                  Sensors of Devices collect Parameters. Parameters are specific
                  measurements that represent what happens in an organism. You
                  can consult the updated list of Parameters on the Vivanta
                  Platform here.
                </p>
                <h5>Device</h5>
                <p>
                  Devices are electronic implements that collect digital health
                  parameters, such as smartwatches, smartbands, smartphones,
                  scales, medical equipment, and other electronic sensors
                  capable of collecting digital health parameters.
                </p>
                <div ref={nextStepsRef}></div>
                <h3>Next steps</h3>
                <p>
                  We would like to show you in a few minutes how easy it can be
                  to integrate health parameters with your product, with the
                  help of Vivanta, so we show you below the diagram with the
                  flow that each element of our product performs, to give you
                  the complete and comprehensive solution you need.
                </p>
                <Row>
                  <Col
                    lg={{ offset: 2, span: 8 }}
                    md={{ offset: 1, span: 10 }}
                    sm={12}
                  >
                    <img
                      className="img-fluid"
                      src={integrationFlow}
                      alt="Integration Flow"
                    />
                  </Col>
                </Row>
                <div className="d-flex justify-content-end mt-4">
                  <Link to={"/quick-start"} className="btn btn-light">
                    Quick start &rarr;
                  </Link>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </Fragment>
  );
};

export default Introduction;

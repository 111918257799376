import { Fragment } from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import LogoSvg from "../Images/logo_vivanta_white.svg";

const Navigation = () => {
  return (
    <Fragment>
      <Navbar bg="vivanta" expand="lg">
        <Container className="mt-2 mb-2">
          <Link to="/" className="text-white">
            <img
              src={LogoSvg}
              height={30}
              alt="Vivanta"
              style={{ color: "white" }}
              className="d-inline-block align-top vivanta-header-logo"
            />{" "}
          </Link>
        </Container>
      </Navbar>
      <div className="mb-2">&nbsp;</div>
    </Fragment>
  );
};

export default Navigation;

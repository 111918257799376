import { Fragment, useEffect, useRef } from "react";
import Navigation from "../Components/Navigation";
import { Container, Row, Col, Alert } from "react-bootstrap";
import Footer from "../Components/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { CopyBlock } from "react-code-blocks";
import connect01 from "../Images/vivanta-connect-01-n.png";
import connect02 from "../Images/vivanta-connect-02-n.png";
import connect03 from "../Images/vivanta-connect-03-n.png";
import { Link } from "react-router-dom";
import Sidebar from "../Components/Sidebar";

const VivantaConnect = () => {
  const webRef = useRef<HTMLDivElement>(null);
  const androidRef = useRef<HTMLDivElement>(null);
  const iosRef = useRef<HTMLDivElement>(null);
  const flutterRef = useRef<HTMLDivElement>(null);
  const reactNativeRef = useRef<HTMLDivElement>(null);
  const ionicRef = useRef<HTMLDivElement>(null);

  const goTo = (ref: any) => {
    ref.current.scrollIntoView();
    ref.current.focus();
  };

  useEffect(() => {
    if (window.location.hash === "#web" && webRef !== null) {
      goTo(webRef);
      return;
    }
    if (window.location.hash === "#android" && androidRef !== null) {
      goTo(androidRef);
      return;
    }
    if (window.location.hash === "#ios" && iosRef !== null) {
      goTo(iosRef);
      return;
    }
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <Navigation />
      <Container className="mt-4">
        <Row>
          <Col xl={2} lg={3} md={4} sm={4}>
            <Sidebar current="connect" />
          </Col>
          <Col xl={10} lg={9} md={8} sm={8}>
            <h1>Vivanta Connect</h1>
            <hr />
            <Row>
              <Col className="documentation">
                <p>
                  Vivanta Connect is a user-friendly front-end application that
                  your Users will interact with to connect their devices to
                  Vivanta and provide you access to their data using the Vivanta
                  API or Webhooks.
                </p>
                <p>
                  By using Vivanta Connect, you gain the following advantages:
                </p>
                <ul>
                  <li>No need to build a front-end element</li>
                  <li>
                    Users log in to their device once, and Vivanta handles
                    access management thereafter
                  </li>
                  <li>
                    Vivanta automatically adds New Brands as they become
                    available. No further development is needed
                  </li>
                </ul>
                <Row className="mt-4 mb-4">
                  <Col>
                    <img
                      src={connect01}
                      alt="Connect 1"
                      className="img-fluid img-polaroid"
                    />
                  </Col>
                  <Col>
                    <img
                      src={connect02}
                      alt="Connect 1"
                      className="img-fluid img-polaroid"
                    />
                  </Col>
                  <Col>
                    <img
                      src={connect03}
                      alt="Connect 1"
                      className="img-fluid img-polaroid"
                    />
                  </Col>
                </Row>
                <Alert variant="info" className="mt-4">
                  <h6>
                    <FontAwesomeIcon icon={faInfoCircle} /> Note
                  </h6>
                  <p>
                    To use Vivanta Connect outside the Sandbox environment,
                    please contact Vivanta and request credentials.
                  </p>
                </Alert>
                <div ref={webRef}></div>
                <h3 id="web">
                  <Link to="#web" className="anchor">
                    Web
                  </Link>
                </h3>
                <p>
                  To integrate Vivanta Connect into your web application, follow
                  these simple steps:
                </p>
                <ol>
                  <li>
                    Copy the following code and add it to your application:
                  </li>
                </ol>
                <CopyBlock
                  text={
                    '<script src="https://connect.vivanta.io/vivanta.min.js"></script>'
                  }
                  language={"javascript"}
                  theme={"dracula"}
                  wrapLines
                />
                <ol start={2} className="mt-4">
                  <li>
                    Next, add the following function to any event of any element
                    in your application. For example, you can add it to a
                    button:
                  </li>
                </ol>
                <CopyBlock
                  text={`
<button onclick="OpenVivantaConnect({
  publicKey: '[publicKey]',
  customerId: '[customerId]',
  externalUserId: '[externalUserId]',
  companyId: '[companyId]',
  callback: [callback]
})">Open Vivanta Widget</button>
                  `}
                  language={"javascript"}
                  theme={"dracula"}
                  wrapLines
                />
                <p className="mt-4">
                  <em>OpenVivantaConnect</em> is a function that accepts an
                  object as parameter, where:
                </p>
                <ul>
                  <li>
                    <strong>publicKey:</strong> is the API key shared by Vivanta
                  </li>
                  <li>
                    <strong>customerId:</strong> is the Customer ID shared by
                    Vivanta
                  </li>
                  <li>
                    <strong>externalUserId:</strong> is the value of the
                    Client's User ID, that allows Vivanta to identify, store and
                    notify specific the User Parameters updates
                  </li>
                  <li>
                    <strong>externalUserId:</strong> is the value of the
                    Client's User ID, that allows Vivanta to identify, store and
                    notify specific the User Parameters updates
                  </li>
                  <li>
                    <strong>companyId:</strong> is an optional value that
                    specify the Company associated to the User
                  </li>
                  <li>
                    <strong>callback:</strong> is an optional callback that is
                    executed after the user closes Vivanta Connect
                  </li>
                </ul>
                <ol start={3}>
                  <li>
                    Finally, to open Vivanta Connect, run your application and
                    press the button where you added the function.
                  </li>
                </ol>
                <p>
                  That's it! You've successfully integrated Vivanta Connect into
                  your web app.
                </p>
                <div ref={androidRef}></div>
                <h3 id="android">
                  <Link to="#android" className="anchor">
                    Android
                  </Link>
                </h3>
                <p>
                  To integrate Vivanta Connect into your Android application,
                  please follow these simple steps:
                </p>
                <ol>
                  <li>
                    Ensure that your application has permission to access the
                    internet enabled. You can do this by adding the following
                    line to your AndroidManifest.xml file:
                  </li>
                </ol>
                <CopyBlock
                  text={
                    '<uses-permission android:name="android.permission.INTERNET"/>'
                  }
                  language={"kotlin"}
                  theme={"atom-one-dark"}
                  wrapLines
                />
                <ol start={2} className="mt-4">
                  <li>
                    Create new Activity called "VivantaConnectActivity", and add
                    this :
                  </li>
                </ol>
                <CopyBlock
                  text={`class VivantaConnectActivity : AppCompatActivity() {
  override fun onCreate(savedInstanceState: Bundle?) {
    super.onCreate(savedInstanceState)
    val vivantaConnect = WebView(this)
    setContentView(vivantaConnect)
    vivantaConnect.settings.javaScriptEnabled = true
    val apiKey: String = ""       // Add your API Key
    val customerId: String = ""   // Add your Customer ID
    val userId: String = ""       // Add the identified User ID
    val widget = "https://widget.vivanta.io/start/[apiKey]/[customerId]/[userId]"
    vivantaConnect.loadUrl(widget)
  }
}`}
                  language={"kotlin"}
                  theme={"dracula"}
                  wrapLines
                />
                <ol start={3} className="mt-4">
                  <li>
                    Invoke the created activity by adding the following code to
                    the button click event or wherever you want to open the
                    Vivanta Connect:
                  </li>
                </ol>
                <CopyBlock
                  text={`val intent = Intent(this, VivantaConnectActivity::class.java)\nstartActivity(intent)`}
                  language={"kotlin"}
                  theme={"dracula"}
                  wrapLines
                />
                <p className="mt-4">
                  That's it! You've successfully integrated Vivanta Connect into
                  your Android app.
                </p>
                <Alert variant="info">
                  <h6>
                    <FontAwesomeIcon icon={faInfoCircle} /> Note
                  </h6>
                  <p>
                    The above example considers an Android application
                    configured with Kotlin and libraries compatible with
                    Lollipop (5.0). However, it is possible to implement with
                    other versions and compatible languages that allow deploying
                    the WebView component
                  </p>
                </Alert>
                <div ref={iosRef}></div>
                <h3 id="ios">
                  <Link to="#ios" className="anchor">
                    iOS
                  </Link>
                </h3>
                <p>
                  To integrate Vivanta Connect into your iOS app, please follow
                  these simple steps:
                </p>
                <ol>
                  <li>
                    Make sure that your application has permission to access the
                    internet enabled. You can do this by adding the following
                    lines of code to your Info.plist file:
                  </li>
                </ol>
                <CopyBlock
                  text={`<key>NSAppTransportSecurity</key>
<dict>
  <key>NSAllowsArbitraryLoads</key>
  <true/>
</dict>`}
                  language={"kotlin"}
                  theme={"dracula"}
                  wrapLines
                />
                <ol start={2} className="mt-4">
                  <li>
                    Create a View that includes a WKWebView component. To do
                    this, add the following code to your SwiftUI view:
                  </li>
                </ol>
                <CopyBlock
                  text={`import SwiftUI
import WebKit
struct VivantaConnect: UIViewRepresentable {
    var url: URL
    func makeUIView(context: Context) -> WKWebView {
        return WKWebView()
    }
    func updateUIView(_ webView: WKWebView, context: Context) {
        let request = URLRequest(url: url)
        webView.load(request)
    }
}`}
                  language={"swift"}
                  theme={"codepen"}
                  wrapLines
                />
                <ol start={3} className="mt-4">
                  <li>
                    Invoke the created view by adding the following code to the
                    button press event or wherever you want to open Vivanta
                    Connect, for example:
                  </li>
                </ol>
                <CopyBlock
                  text={`val intent = Intent(this, VivantaConnectActivity::class.java)\nstartActivity(intent)`}
                  language={"kotlin"}
                  theme={"dracula"}
                  wrapLines
                />
                <Alert variant="info" className="mt-4">
                  <h6>
                    <FontAwesomeIcon icon={faInfoCircle} /> Note
                  </h6>
                  <p>
                    The above example considers an iPhone app configured with
                    Swift, compatible with SwiftUI and iOS 14. However, it is
                    possible to implement Vivanta Connect with other versions
                    that allow deploying the WKWebView component.
                  </p>
                </Alert>
                <div ref={flutterRef}></div>
                <h3 id="flutter">
                  <Link to="#flutter" className="anchor">
                    Flutter
                  </Link>
                </h3>
                <p>
                  To integrate Vivanta Connect into your Flutter app, please use
                  our official plugin available on{" "}
                  <a
                    target="_blank"
                    href="https://pub.dev/packages/vivanta_connect_flutter"
                    rel="noreferrer"
                  >
                    pub.dev
                  </a>
                  .
                </p>
                <div ref={reactNativeRef}></div>
                <h3 id="react-native">
                  <Link to="#react-native" className="anchor">
                    React Native
                  </Link>
                </h3>
                <p>
                  To integrate Vivanta Connect into your React Native app,
                  please use our official plugin available on{" "}
                  <a
                    target="_blank"
                    href="https://www.npmjs.com/package/vivanta-connect-react-native"
                    rel="noreferrer"
                  >
                    npmjs.org
                  </a>
                  .
                </p>
                <div ref={ionicRef}></div>
                <h3 id="ionic">
                  <Link to="#ionic" className="anchor">
                    Ionic
                  </Link>
                </h3>
                <p>Coming soon!</p>
              </Col>
            </Row>
            <div className="d-flex justify-content-between mt-4">
              <Link to={"/quick-start"} className="btn btn-light">
                &larr; Quickstart
              </Link>
              <Link to={"/apple-health"} className="btn btn-light">
                Apple Health &rarr;
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </Fragment>
  );
};

export default VivantaConnect;

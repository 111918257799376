import React, { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import "../Styles/Dashboard.css";
import Sticky from "react-stickynode";
import { Link } from "react-router-dom";

const Sidebar = (props: any) => {
  const current = props.current ?? "";

  const [vivantaConnectVisible, setVivantaConnectVisible] = useState(false);

  const toggle = () => setVivantaConnectVisible(!vivantaConnectVisible);

  useEffect(() => {
    if (current === "apple-health") {
      setVivantaConnectVisible(true);
    }
    if (current === "google-health-connect") {
      setVivantaConnectVisible(true);
    }
    if (current === "connect") {
      setVivantaConnectVisible(true);
    }
  }, [current]);

  return (
    <Sticky>
      <Nav
        className="col-md-12 d-none d-sm-block sidebar"
        activeKey="/"
        onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
      >
        <div className="sidebar-sticky"></div>
        <Nav.Item>
          <Link
            to={"/introduction"}
            className={`sidebar-link ${
              current === "introduction" ? "sidebar-selected" : ""
            }`}
          >
            Introduction
          </Link>
        </Nav.Item>
        <Nav.Item>
          <Link
            to={"/quick-start"}
            className={`sidebar-link ${
              current === "quickstart" ? "sidebar-selected" : ""
            }`}
          >
            Quickstart
          </Link>
        </Nav.Item>
        <Nav.Item>
          <a onClick={toggle} className="sidebar-link">
            Vivanta Connect
          </a>
          <Nav.Item hidden={!vivantaConnectVisible}>
            <Link
              to={"/connect"}
              className={`sidebar-link ${
                current === "connect" ? "sidebar-selected" : ""
              }`}
            >
              &nbsp;&nbsp;- How to integrate
            </Link>
          </Nav.Item>
          <Nav.Item hidden={!vivantaConnectVisible}>
            <Link
              to={"/apple-health"}
              className={`sidebar-link ${
                current === "apple-health" ? "sidebar-selected" : ""
              }`}
            >
              &nbsp;&nbsp;- Apple Health
            </Link>
          </Nav.Item>
          <Nav.Item hidden={!vivantaConnectVisible}>
            <Link
              to={"/google-health-connect"}
              className={`sidebar-link ${
                current === "google-health-connect" ? "sidebar-selected" : ""
              }`}
            >
              &nbsp;&nbsp;- Google Health Connect
            </Link>
          </Nav.Item>
        </Nav.Item>
        <Nav.Item>
          <Link
            to={"/api"}
            className={`sidebar-link ${
              current === "api" ? "sidebar-selected" : ""
            }`}
          >
            API overview
          </Link>
        </Nav.Item>
        <Nav.Item>
          <Link
            to={"/webhooks"}
            className={`sidebar-link ${
              current === "webhooks" ? "sidebar-selected" : ""
            }`}
          >
            Webhooks
          </Link>
        </Nav.Item>
        <Nav.Item>
          <Link
            to={"/parameters"}
            className={`sidebar-link ${
              current === "parameters" ? "sidebar-selected" : ""
            }`}
          >
            Parameters
          </Link>
        </Nav.Item>
        <Nav.Item>
          <Link
            to={"/activities"}
            className={`sidebar-link ${
              current === "activities" ? "sidebar-selected" : ""
            }`}
          >
            Activities
          </Link>
        </Nav.Item>
        <Nav.Item>
          <Link
            to={"/intraday"}
            className={`sidebar-link ${
              current === "intraday" ? "sidebar-selected" : ""
            }`}
          >
            Intraday
          </Link>
        </Nav.Item>
        <Nav.Item>
          <Link
            to={"/embedded-graphs"}
            className={`sidebar-link ${
              current === "graphs" ? "sidebar-selected" : ""
            }`}
          >
            Embedded Graphs
          </Link>
        </Nav.Item>
      </Nav>
    </Sticky>
  );
};

export default Sidebar;

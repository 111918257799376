import { Fragment, useEffect, useRef } from "react";
import Navigation from "../Components/Navigation";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../Components/Footer";
import integrationFlow from "../Images/integration_flow.png";
import { Link } from "react-router-dom";
import { CopyBlock } from "react-code-blocks";
import Sidebar from "../Components/Sidebar";

const Webhooks = () => {
  const connectedUser = useRef<HTMLDivElement>(null);
  const updatedParameters = useRef<HTMLDivElement>(null);
  const updatedActivities = useRef<HTMLDivElement>(null);

  const goTo = (ref: any) => {
    ref.current.scrollIntoView();
    ref.current.focus();
  };

  useEffect(() => {
    if (window.location.hash === "#connected-users" && connectedUser !== null) {
      goTo(connectedUser);
      return;
    }
    if (
      window.location.hash === "#updated-parameters" &&
      updatedParameters !== null
    ) {
      goTo(updatedParameters);
      return;
    }
    if (
      window.location.hash === "#updated-activities" &&
      updatedActivities !== null
    ) {
      goTo(updatedActivities);
      return;
    }
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <Navigation />
      <Container className="mt-4">
        <Row>
          <Col xl={2} lg={3} md={4} sm={4}>
            <Sidebar current="webhooks" />
          </Col>
          <Col xl={10} lg={9} md={8} sm={8}>
            <h1>Webhooks</h1>
            <hr />
            <Row>
              <Col className="documentation">
                <p>
                  Vivanta uses webhooks to notify your backend when an event
                  happens with your Users. Webhooks are particularly useful for
                  asynchronous events like a user completing a Brand
                  integration.
                </p>
                <p>
                  A webhook enables Vivanta to push real-time notifications to
                  your app. You can use these notifications to execute actions
                  in your backend. Vivanta uses HTTPS to send these
                  notifications to your backend as a JSON payload.
                </p>
                <p>
                  You can start receiving event notifications in your app using
                  the steps in this section:
                </p>
                <ol>
                  <li>
                    Create a webhook endpoint as an HTTP endpoint (URL) on your
                    local server.
                  </li>
                  <li>
                    Handle requests from Vivanta by parsing each event object
                    and returning 2xx response status codes.
                  </li>
                  <li>
                    Add your endpoints by requesting the Users Webhook or
                    Parameters Webhook (link to requests in the API).
                  </li>
                  <li>
                    Deploy your webhook endpoint, so it’s a publicly accessible
                    HTTPS URL.
                  </li>
                  <li>
                    Test that your webhook endpoint is working correctly by
                    requesting the Test User Webhook or the Test Parameters
                    Webhook (link to requests in the API).
                  </li>
                </ol>
                <div ref={connectedUser}></div>
                <h3>User Connected</h3>
                <p>
                  The User Connected Webhook allow you to know when a user
                  successfully completed the Vivanta Connect process. The
                  payload sent by Vivanta looks like this:
                </p>
                <CopyBlock
                  text={`{
    event: 'subscribe',
    data: {
        externalUserId: 239,
        date: ‘2023-01-18T01:00:11.886Z’,
        brandId: 1,
    },
}`}
                  language={"json"}
                  theme={"dracula"}
                  wrapLines
                />
                <div ref={updatedParameters}></div>
                <h3>Parameters updated</h3>
                <p>
                  Parameters update Webhook allow to obtain the update Parameter
                  Data for connected users. The payload sent by Vivanta looks
                  like this:
                </p>
                <CopyBlock
                  text={`{
    "event": "update",
    "data": {
        "externalUserId": 35,
        "brandId": 3,
        "parameters": [
            {
                "value": "300",
                "unit": "steps",
                "parameter": "steps",
                "parameter_type": "activity",
                "date": "2022-08-17"
            },
            {
                "value": "24",
                "unit": "min",
                "parameter": "deep_sleep_duration",
                "parameter_type": "sleep",
                "date": "2022-08-17"
            }
        ]  
    },
}`}
                  language={"json"}
                  theme={"dracula"}
                  wrapLines
                />
                <div ref={updatedActivities}></div>
                <h3>Activities updated</h3>
                <p>
                  Activities update Webhook allow to obtain the update Activity
                  Data for connected users. The payload sent by Vivanta looks
                  like this:
                </p>
                <CopyBlock
                  text={`{
  event: 'activity',
  data: {
    activityType: 'RUNNING',
    parameters: {
      distance: {
        parameter: 'distance',
        value: 12.14,
        unit: 'km',
      },
      active_calories: {
        parameter: 'active_calories',
        value: 1085,
        unit: 'kcal',
      },
      duration: {
        parameter: 'duration',
        value: '5078.965',
        unit: 's',
      },
    },
    date: textDate,
  },
},`}
                  language={"json"}
                  theme={"dracula"}
                  wrapLines
                />
              </Col>
              <div className="d-flex justify-content-between mt-4">
                <Link to={"/api"} className="btn btn-light">
                  &larr; API overview
                </Link>
                <Link to={"/parameters"} className="btn btn-light">
                  Parameters &rarr;
                </Link>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </Fragment>
  );
};

export default Webhooks;

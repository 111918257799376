import { Fragment, useEffect, useRef } from "react";
import Navigation from "../Components/Navigation";
import { Container, Row, Col, Alert, Table } from "react-bootstrap";
import Footer from "../Components/Footer";
import integrationFlow from "../Images/integration_flow.png";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Sidebar from "../Components/Sidebar";

const Parameters = () => {
  const definitionRef = useRef<HTMLDivElement>(null);
  const parametersAvailable = useRef<HTMLDivElement>(null);
  const unitConversionSimplified = useRef<HTMLDivElement>(null);

  const goTo = (ref: any) => {
    ref.current.scrollIntoView();
    ref.current.focus();
  };

  useEffect(() => {
    if (window.location.hash === "#definition" && definitionRef !== null) {
      goTo(definitionRef);
      return;
    }
    if (window.location.hash === "#available" && parametersAvailable !== null) {
      goTo(parametersAvailable);
      return;
    }
    if (
      window.location.hash === "#unit-conversion-simplified" &&
      unitConversionSimplified !== null
    ) {
      goTo(unitConversionSimplified);
      return;
    }
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <Navigation />
      <Container className="mt-4">
        <Row>
          <Col xl={2} lg={3} md={4} sm={4}>
            <Sidebar current="parameters" />
          </Col>
          <Col xl={10} lg={9} md={8} sm={8}>
            <h1>Parameters</h1>
            <hr />
            <Row>
              <Col className="documentation">
                <p>
                  Wearable technology can collect a vast amount of data from a
                  single individual. For some devices, this can mean over 100
                  parameters. The Vivanta platform, helps you seamlessly gain
                  access to this valuable data.
                </p>
                <div ref={definitionRef}></div>
                <h3>Definition</h3>
                <p>
                  A <strong>parameter</strong> is an objective measurement or
                  calculation that captures what is happening within an organism
                  at a given moment. For example, a person's Resting Heart Rate
                  (RHR), measured in beats per minute, is a parameter that
                  relates to cardiovascular health.
                </p>
                <p>
                  At Vivanta, we classify parameters into four distinct
                  categories:
                </p>
                <ul>
                  <li>
                    <strong>Activity Parameters:</strong> related to actigraphy,
                    are used to measure movement, providing a detailed
                    understanding of a person's physical activity patterns.
                  </li>
                  <li>
                    <strong>Sleep Parameters:</strong> designed to capture
                    information related to the sleep process such as sleep
                    duration, sleep stages and sleep efficiency.
                  </li>
                  <li>
                    <strong>Body Parameters:</strong> mostly anthropometry, but
                    also in relation to diet.
                  </li>
                  <li>
                    <strong>Heart Parameters:</strong> including a range of
                    measurements and calculations that capture information
                    related to cardiovascular health. These parameters provide
                    valuable insights into a person's heart rate, heart rate
                    variability, and cardiac output.
                  </li>
                </ul>
                <div ref={parametersAvailable}></div>
                <h3>Parameters available</h3>
                <p>
                  Vivanta offers a wide range of brand integrations and
                  parameters for health companies and researchers to retrieve.
                  Currently, we have 6 primary integrations (Apple Health,
                  Google Health Connect, Fitbit, Polar, Garmin, Withings) from
                  which you can quickly obtain the following parameters:
                </p>
                <Table striped bordered className="mt-4 mb-4">
                  <thead>
                    <tr>
                      <th>BRAND</th>
                      <th>ACTIVITY PARAMETERS</th>
                      <th>SLEEP PARAMETERS</th>
                      <th>BODY PARAMETERS</th>
                      <th>HEART PARAMETERS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Fitbit</th>
                      <td>
                        Active Calories*
                        <br />
                        Total Calories
                        <br />
                        BMR Calories
                        <br />
                        Steps
                        <br />
                        Distance
                      </td>
                      <td>
                        Light Sleep Duration
                        <br />
                        Deep Sleep Duration
                        <br />
                        REM Sleep Duration
                        <br />
                        Total Sleep Duration
                      </td>
                      <td>
                        Weight
                        <br />
                        Height
                        <br />
                        BMI
                        <br />
                        VO2Max
                        <br />
                        SPO2
                        <br />
                        Skin Temperature
                      </td>
                      <td>
                        Resting HR
                        <br />
                        Heart Rate Variability
                      </td>
                    </tr>
                    <tr>
                      <th>Polar</th>
                      <td>
                        Active Calories
                        <br />
                        Total Calories
                        <br />
                        BMR Calories*
                        <br />
                        Steps
                      </td>
                      <td>
                        Light Sleep Duration
                        <br />
                        Deep Sleep Duration
                        <br />
                        REM Sleep Duration
                        <br />
                        Total Sleep Duration*
                      </td>
                      <td>
                        Weight
                        <br />
                        Height
                        <br />
                        BMI*
                        <br />
                        VO2Max
                      </td>
                      <td>
                        Resting HR
                        <br />
                        Max HR
                        <br />
                        Heart Rate Variability
                        <br />
                        Diastolic Blood Pressure
                        <br />
                        Systolic Blood Pressure
                      </td>
                    </tr>
                    <tr>
                      <th>Garmin</th>
                      <td>
                        Active Calories
                        <br />
                        Total Calories*
                        <br />
                        BMR Calories
                        <br />
                        Steps
                        <br />
                        Distance
                      </td>
                      <td>
                        Light Sleep Duration
                        <br />
                        Deep Sleep Duration
                        <br />
                        REM Sleep Duration
                        <br />
                        Total Sleep Duration
                      </td>
                      <td>
                        Weight
                        <br />
                        Height*
                        <br />
                        BMI
                        <br />
                        VO2Max
                        <br />
                        SPO2
                      </td>
                      <td>
                        Resting HR
                        <br />
                        Max HR
                        <br />
                        Min HR
                        <br />
                        Heart Rate Variability
                        <br />
                        Diastolic Blood Pressure
                        <br />
                        Systolic Blood Pressure
                      </td>
                    </tr>
                    <tr>
                      <th>Withings</th>
                      <td>
                        Active Calories
                        <br />
                        Total Calories
                        <br />
                        BMR Calories*
                        <br />
                        Steps
                        <br />
                        Distance
                      </td>
                      <td>
                        Light Sleep Duration
                        <br />
                        Deep Sleep Duration
                        <br />
                        REM Sleep Duration
                        <br />
                        Total Sleep Duration
                      </td>
                      <td>
                        Weight
                        <br />
                        Height
                        <br />
                        BMI*
                        <br />
                        VO2Max
                        <br />
                        SPO2
                        <br />
                        Skin Temperature
                      </td>
                      <td>
                        Max HR
                        <br />
                        Min HR
                        <br />
                        Heart Rate Variability
                        <br />
                        Diastolic Blood Pressure
                        <br />
                        Systolic Blood Pressure
                      </td>
                    </tr>
                    <tr>
                      <th>Apple Health</th>
                      <td>
                        Active Calories
                        <br />
                        Total Calories
                        <br />
                        BMR Calories
                        <br />
                        Steps
                        <br />
                        Distance
                      </td>
                      <td>
                        Light Sleep Duration
                        <br />
                        Deep Sleep Duration
                        <br />
                        REM Sleep Duration
                        <br />
                        Total Sleep Duration
                      </td>
                      <td>
                        Weight
                        <br />
                        Height
                        <br />
                        BMI
                        <br />
                        SPO2
                        <br />
                        Skin Temperature
                      </td>
                      <td>
                        Resting Heart Rate
                        <br />
                        Max HR
                        <br />
                        Min HR
                        <br />
                        Diastolic Blood Pressure
                        <br />
                        Systolic Blood Pressure
                      </td>
                    </tr>
                    <tr>
                      <th>Google Health Connect</th>
                      <td>
                        Active Calories
                        <br />
                        Total Calories
                        <br />
                        BMR Calories
                        <br />
                        Steps
                        <br />
                        Distance
                      </td>
                      <td>
                        Light Sleep Duration
                        <br />
                        Deep Sleep Duration
                        <br />
                        REM Sleep Duration
                        <br />
                        Total Sleep Duration
                      </td>
                      <td>
                        Weight
                        <br />
                        Height
                        <br />
                        BMI
                        <br />
                        SPO2
                      </td>
                      <td>
                        Resting Heart Rate
                        <br />
                        Max HR
                        <br />
                        Min HR
                        <br />
                        Diastolic Blood Pressure
                        <br />
                        Systolic Blood Pressure
                      </td>
                    </tr>
                    <tr>
                      <th>Whoop</th>
                      <td>
                        Total Calories
                        <br />
                        Steps
                      </td>
                      <td>
                        Light Sleep Duration
                        <br />
                        Deep Sleep Duration
                        <br />
                        REM Sleep Duration
                        <br />
                        Total Sleep Duration
                      </td>
                      <td>
                        SPO2
                        <br />
                        Skin temperature
                        <br />
                        Breathing rate
                      </td>
                      <td>
                        Resting Heart Rate
                        <br />
                        Max HR
                        <br />
                        Average HR
                        <br />
                        HRV
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <p>
                  Parameters marked * are provided by Vivanta performing
                  calculations using other provided parameters. These parameters
                  are known as Calculated Parameters.
                </p>
                <Alert variant="info">
                  <h6>
                    <FontAwesomeIcon icon={faInfoCircle} /> Data Accesibility
                  </h6>
                  <p>
                    Vivanta can access data that is synced to any brand's API
                    through any of their devices. However, it's important to
                    keep in mind that the available parameters will vary based
                    on the device used, as not all devices measure the same set
                    of parameters.
                  </p>
                </Alert>
                <div ref={unitConversionSimplified}></div>
                <h3>Unit conversion simplified</h3>
                <p>
                  Dealing with different <strong>unit systems</strong> can be a
                  tedious task that can impede quick data analysis. To make
                  things easier, we've implemented a solution for you. When you
                  use Vivanta to retrieve data, you have the option to request
                  it in 3 unit systems: Raw (exactly how the brands deliver the
                  data), Imperial Units (for customers in the US and UK) and the
                  Vivanta Standard Units. This way you don't have to spend time
                  converting data and can focus on utilizing it effectively.
                </p>
                <Table striped bordered className="mt-4 mb-4">
                  <thead>
                    <tr>
                      <th>PARAMETER NAME</th>
                      <th>RAW DATA</th>
                      <th>VIVANTA STANDARD UNITS</th>
                      <th>IMPERIAL UNITS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Weight</td>
                      <td>Brand</td>
                      <td>kg</td>
                      <td>lb</td>
                    </tr>
                    <tr>
                      <td>Height</td>
                      <td>Brand</td>
                      <td>m</td>
                      <td>ft</td>
                    </tr>
                    <tr>
                      <td>Calories</td>
                      <td>Brand</td>
                      <td>kcal</td>
                      <td>kcal</td>
                    </tr>
                    <tr>
                      <td>REM Sleep</td>
                      <td>Brand</td>
                      <td>min</td>
                      <td>min</td>
                    </tr>
                    <tr>
                      <td>BMI</td>
                      <td>Brand</td>
                      <td>kg/m^2</td>
                      <td>kg/m^2</td>
                    </tr>
                    <tr>
                      <td>Distance</td>
                      <td>Brand</td>
                      <td>km</td>
                      <td>mi</td>
                    </tr>
                    <tr>
                      <td>Heart Rate</td>
                      <td>Brand</td>
                      <td>bpm</td>
                      <td>bpm</td>
                    </tr>
                  </tbody>
                </Table>
                <Alert variant="info">
                  <h6>
                    <FontAwesomeIcon icon={faInfoCircle} /> Vivanta Standard
                    Units
                  </h6>
                  <p>
                    The Vivanta Standard Units are a proprietary unit system
                    created by Vivanta to present data in a format that aligns
                    with scientific literature.
                  </p>
                </Alert>
                <div className="d-flex justify-content-between mt-4">
                  <Link to={"/webhooks"} className="btn btn-light">
                    &larr; Webhooks
                  </Link>
                  <Link to={"/activities"} className="btn btn-light">
                    Activities &rarr;
                  </Link>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </Fragment>
  );
};

export default Parameters;

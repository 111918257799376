import { Fragment, useEffect, useRef } from "react";
import Navigation from "../Components/Navigation";
import { Container, Row, Col, Alert } from "react-bootstrap";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import { CodeBlock, CopyBlock } from "react-code-blocks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Sidebar from "../Components/Sidebar";

const QuickStart = () => {
  const connectRef = useRef<HTMLDivElement>(null);
  const apiRef = useRef<HTMLDivElement>(null);
  const webhooksRef = useRef<HTMLDivElement>(null);

  const goTo = (ref: any) => {
    ref.current.scrollIntoView();
    ref.current.focus();
  };

  useEffect(() => {
    if (window.location.hash === "#connect" && connectRef !== null) {
      goTo(connectRef);
      return;
    }
    if (window.location.hash === "#api" && apiRef !== null) {
      goTo(apiRef);
      return;
    }
    if (window.location.hash === "#webhooks" && webhooksRef !== null) {
      goTo(webhooksRef);
      return;
    }
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <Navigation />
      <Container className="mt-4">
        <Row>
          <Col xl={2} lg={3} md={4} sm={4}>
            <Sidebar current="quickstart" />
          </Col>
          <Col xl={10} lg={9} md={8} sm={8}>
            <h1>Quickstart</h1>
            <hr />
            <Row>
              <Col className="documentation">
                <p>
                  The Vivanta platform is designed with speed of integration at
                  its core. Therefore, we have developed a series of elements
                  that will facilitate your integration. Below you can follow
                  the step-by-step integration process.
                </p>
                <div ref={connectRef}></div>
                <h3>Vivanta Connect</h3>
                <p>
                  Integrating Vivanta Connect into your web application is as
                  easy as 1-2-3, you can give it a go using our Sandbox version
                  following this 3 simple steps:
                </p>
                <ol>
                  <li>
                    Copy the following code into your web application. We
                    recommend to paste before your &lt;/body&gt; tag.
                  </li>
                </ol>
                <CopyBlock
                  text={
                    '<script src="https://connect.vivanta.io/vivanta.min.js"></script>'
                  }
                  language={"javascript"}
                  theme={"dracula"}
                  wrapLines
                />
                <ol start={2} className="mt-2">
                  <li>
                    Add the function <strong>OpenVivantaConnectSandbox</strong>{" "}
                    to any event of some element of your application. For
                    example:
                  </li>
                </ol>
                <Alert variant="info">
                  <FontAwesomeIcon icon={faInfoCircle} />{" "}
                  OpenVivantaConnectSandbox can receive a callback that will be
                  executed after Vivanta Connect is closed.
                </Alert>
                <ol start={3} className="mt-2">
                  <li>
                    Run your application, and press the button where you added
                    the function that runs Vivanta Connect.
                  </li>
                </ol>
                <p>
                  For mobile applications, you can add a Webview that render the
                  following URL:{" "}
                  <a href="https://connect.vivanta.io">
                    https://connect.vivanta.io
                  </a>
                  .
                </p>
                <div ref={apiRef}></div>
                <h3>API</h3>
                <p>Make test requests to our API in Sandbox mode:</p>
                <ol>
                  <li>
                    "Show all Users" request is used to view the list of users
                    who have opened Vivanta Connect
                  </li>
                </ol>
                <ol start={2}>
                  <li>
                    "Show Parameters" request is used to retrieve the Parameters
                    of a Connected User
                  </li>
                </ol>
                <p>
                  <Link to={"/api"}>
                    Click here to review the full API Reference
                  </Link>
                  .
                </p>
                <div ref={webhooksRef}></div>
                <h3>Webhooks</h3>
                <p>
                  We suggest you use Webhooks as a priority over the API
                  endpoints, as it allows for real-time notifications to be
                  sent, as soon as activities occur.
                </p>
                <p>
                  In order to properly receive Webhooks from Vivanta, the
                  client's backend must have the following features:
                </p>
                <ul>
                  <li>Compatibility with HTTP Requests</li>
                  <li>An endpoint with POST method to receive notifications</li>
                  <li>
                    If CORS is active, add a policy to allow receiving POST
                    requests from the domain vivanta.io
                  </li>
                  <li>
                    The programming language must allow for parsing of strings
                    in JSON format, as it is the format in which the Body of the
                    POST request is sent
                  </li>
                </ul>
                <h5>User Integrated Webhook</h5>
                <p>
                  When a user authorizes access to their Parameter Data, the
                  following Payload is sent to the endpoint indicated by the
                  client:
                </p>
                <CodeBlock
                  text={`{
  "event": "subscribe",
  "data": {
    "externalUserId": 239,
    "date": "2023-01-18T01:00:11.886Z",
    "brandId": 1
  }
}`}
                  numberLines={true}
                  language={"javascript"}
                  theme={"dracula"}
                  wrapLines
                />
                <p className="mt-4">Where:</p>
                <ul>
                  <li>
                    <strong>event:</strong> name of the Webhook event. The
                    "subscribe" event indicates that a user has authorized the
                    reading of their Parameter Data
                  </li>
                  <li>
                    <strong>data:</strong> inside the "data" object will be the
                    information related to the Webhook event
                    <ul>
                      <li>
                        <strong>externalUserId:</strong> the ID of the User that
                        was integrated (we consider external the ID that the
                        client uses to identify its User)
                      </li>
                      <li>
                        <strong>date:</strong> date and time in ISO format in
                        which the integration was made
                      </li>
                      <li>
                        <strong>brandId:</strong> the ID of the Brand with which
                        the User made the integration
                      </li>
                    </ul>
                  </li>
                </ul>
                <h5>Parameters Updated Webhook</h5>
                <p>
                  When a Brand updates the User Paramater Data, the following
                  payload is an example of the payload that is sent when new
                  parameter data is obtained:
                </p>
                <CodeBlock
                  text={`{
  "event": "update",
  "data": {
    "externalUserId": 35,
    "brandId": 3,
    "parameters": [
      {
        "value": "300",
        "unit": "steps",
        "parameter": "steps",
        "parameter_type": "activity",
        "date": "2022-08-17"
      },
      {
        "value": "24",
        "unit": "min",
        "parameter": "deep_sleep_duration",
        "parameter_type": "sleep",
        "date": "2022-08-17"
      }
    ]  
  },
}`}
                  numberLines={true}
                  language={"javascript"}
                  theme={"dracula"}
                  wrapLines
                />
                <p className="mt-4">Where</p>
                <ul>
                  <li>
                    <strong>event:</strong> name of the Webhook event. The
                    "update" event indicates that a Brand has update the
                    Parameter Data
                  </li>
                  <li>
                    <strong>data:</strong> inside the "data" object will be the
                    information related to the Webhook event
                  </li>
                  <li>
                    <strong>externalUserId:</strong> the ID of the User that
                    belongs the Parameter Data
                  </li>
                  <li>
                    <strong>brandId:</strong> the ID of the Brand that sends the
                    Parameter Data update
                  </li>
                  <li>
                    <strong>parameters:</strong> this is an array that contains
                    all the updated parameters. Every object of this array
                    contains:
                    <ul>
                      <li>
                        <strong>value:</strong> the value of the parameter
                      </li>
                      <li>
                        <strong>unit:</strong> the measurement unit of the
                        updated parameter
                      </li>
                      <li>
                        <strong>parameter:</strong> the name of the updated
                        parameter
                      </li>
                      <li>
                        <strong>parameter_type:</strong> the type of the updated
                        parameter
                      </li>
                      <li>
                        <strong>date:</strong> The date of the updated parameter
                        in format "yyyy-mm-dd"
                      </li>
                    </ul>
                  </li>
                </ul>
                <div className="d-flex justify-content-between mt-4">
                  <Link to={"/introduction"} className="btn btn-light">
                    &larr; Introduction
                  </Link>
                  <Link to={"/connect"} className="btn btn-light">
                    Vivanta Connect &rarr;
                  </Link>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </Fragment>
  );
};

export default QuickStart;

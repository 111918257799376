import { Fragment, useEffect, useRef } from "react";
import Navigation from "../Components/Navigation";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../Components/Footer";
import "swagger-ui-react/swagger-ui.css";
import { Link } from "react-router-dom";
import Sidebar from "../Components/Sidebar";

const AppleHealth = () => {
  const requirementsRef = useRef<HTMLDivElement>(null);

  const goTo = (ref: any) => {
    ref.current.scrollIntoView();
    ref.current.focus();
  };

  useEffect(() => {
    if (window.location.hash === "#overview" && requirementsRef !== null) {
      goTo(requirementsRef);
      return;
    }
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <Navigation />
      <Container className="mt-4">
        <Row>
          <Col xl={2} lg={3} md={4} sm={4}>
            <Sidebar current="apple-health" />
          </Col>
          <Col xl={10} lg={9} md={8} sm={8}>
            <h1>Apple Health</h1>
            <hr />
            <Row>
              <Col className="documentation">
                <p>
                  You can easily integrate Apple Health using Vivanta to collect
                  and analyze users&#39; health data. With this integration, you
                  can provide personalized health insights and recommendations
                  to your users based on their health metrics from Apple
                  HealthKit. To add Apple Health to your iOS app, follow these
                  steps.{" "}
                </p>
                <div ref={requirementsRef}></div>
                <h3>Requirements</h3>
                <ol>
                  <li>You have an iOS app</li>
                  <li>Your application is compatible with iOS 13+</li>
                  <li>
                    Your application is published or will be available in the
                    App Store
                  </li>
                  <li>
                    You have identified the specific Apple HealthKit health
                    parameters you will use
                  </li>
                </ol>
                <p>
                  A <strong>native iOS app is mandatory</strong> for integrating
                  with HealthKit
                </p>
                <p>
                  Given the sensitive nature of health information, Apple has
                  implemented stringent privacy and security measures. As per
                  the HealthKit Framework Documentation:
                </p>
                <blockquote>
                  <p>
                    The HealthKit data is only kept locally on the user’s
                    device. For security, the HealthKit store is encrypted when
                    the device is not unlocked. The HealthKit data store can
                    only be accessed by an authorized app. You cannot access
                    HealthKit from extensions (like the Today view) or a
                    WatchKit app.
                  </p>
                </blockquote>
                <p>
                  It&#39;s worth noting that HealthKit is inaccessible to iPadOS
                  (version 16 or lower) and MacOS.
                </p>
                <h3>
                  Converting my app into an Authorized Apple HealthKit App
                </h3>
                <p>In Apple Developer:</p>
                <ol>
                  <li>
                    Log in to your account at{" "}
                    <a href="https://developer.apple.com/">
                      https://developer.apple.com/
                    </a>
                  </li>
                  <li>Go to “Certificates, Identifiers &amp; Profiles”</li>
                  <li>
                    In the “Identifiers” section, select the application that
                    will use Apple HealthKit.
                    <strong>Important</strong>: HealthKit cannot be used with a
                    Wildcard Identifier
                  </li>
                  <li>In the Capabilities list, select “HealthKit”</li>
                  <li>
                    Click the “Save” button. <strong>Important</strong>: This
                    change will invalidate all previous Provisioning Profiles of
                    your app. Please modify this when you’re ready to
                    re-generate new Provisioning Profiles
                  </li>
                  <li>
                    Select the type of the new Provisioning Profile
                    (development, production, etc.)
                  </li>
                  <li>
                    Select your previously configured app for linking the
                    Provisioning Profile.
                    <strong>Remember</strong>: Wildcard cannot work with Apple
                    HealthKit
                  </li>
                  <li>
                    Select your previously configured app for linking the
                    Provisioning Profile. Remember: Wildcard can’t work with
                    Apple HealthKit
                  </li>
                  <li>
                    For developer Provisioning Profiles, select the computers
                    and devices that will be provisioned
                  </li>
                  <li>
                    Click on the “Save” button. Then, download the{" "}
                    <code>.mobileprovision</code> file
                  </li>
                </ol>
                <p>In your Xcode</p>
                <ol>
                  <li>Open your app project</li>
                  <li>
                    Go to the “Sign in &amp; Capabilities” menu, select your
                    root project
                  </li>
                  <li>
                    Import the newly created Provisioning Profile and select it{" "}
                  </li>
                  <li>
                    <p>
                      Add these elements in your Info.plist. Apple will validate
                      this information{" "}
                    </p>
                    <pre>
                      <code className="lang-xml">
                        {" "}
                        &lt;<span className="hljs-keyword">key</span>
                        &gt;NSHealthShareUsageDescription&lt;/
                        <span className="hljs-keyword">key</span>&gt; &lt;
                        <span className="hljs-built_in">string</span>&gt;[Add
                        here the purpose{" "}
                        <span className="hljs-keyword">for</span> reading{" "}
                        <span className="hljs-keyword">from</span> Apple
                        Health]&lt;/
                        <span className="hljs-built_in">string</span>&gt; &lt;
                        <span className="hljs-keyword">key</span>
                        &gt;NSHealthUpdateUsageDescription&lt;/
                        <span className="hljs-keyword">key</span>&gt; &lt;
                        <span className="hljs-built_in">string</span>&gt;[Add
                        here the purpose{" "}
                        <span className="hljs-keyword">for</span> writing{" "}
                        <span className="hljs-keyword">to</span> Apple
                        Health]&lt;/
                        <span className="hljs-built_in">string</span>&gt;
                      </code>
                    </pre>
                  </li>
                </ol>
                <h3>Integrating Apple Health via Vivanta Connect</h3>
                <ul>
                  <li>
                    To start using Apple Health, Vivanta will provide customized
                    libraries you will need to import into your iOS project.
                  </li>
                  <li>
                    Once you have imported the libraries, you will need to
                    choose a place in your app to place a button to launch the
                    Vivanta Connect native plugin.
                  </li>
                  <li>
                    Additionally, you will need to identify a place in your app
                    where Vivanta Connect will have access to obtain HealthKit
                    parameters. Only your app will be able to access these
                    parameters, so it&#39;s essential to define the periodicity
                    of access.
                  </li>
                  <li>
                    Finally, the analysis and availability of the parameters
                    into the Vivanta API and Webhooks will be automatically
                    processed by the Vivanta Connect plugin.
                  </li>
                </ul>
                <p>
                  If you have questions about how to integrate Vivanta Connect,
                  please contact us to schedule a call.
                </p>
              </Col>
            </Row>

            <Row>
              <div className="d-flex justify-content-between mt-4">
                <Link to={"/connect"} className="btn btn-light">
                  &larr; Vivanta Connect
                </Link>
                <Link to={"/google-health-connect"} className="btn btn-light">
                  Google Health Connect &rarr;
                </Link>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </Fragment>
  );
};

export default AppleHealth;

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Introduction from "./Pages/Introduction";
import QuickStart from "./Pages/QuickStart";
import Api from "./Pages/Api";
import VivantaConnect from "./Pages/VivantaConnect";
import Webhooks from "./Pages/Webhooks";
import Parameters from "./Pages/Parameters";
import Activities from "./Pages/Activities";
import EmbeddedGraphs from "./Pages/EmbeddedGraphs";
import AppleHealth from "./Pages/AppleHealth";
import GoogleHealthConnect from "./Pages/GoogleHealthConnect";
import Intraday from "./Pages/Intraday";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/introduction" element={<Introduction />} />
        <Route path="/quick-start" element={<QuickStart />} />
        <Route path="/apple-health" element={<AppleHealth />} />
        <Route
          path="/google-health-connect"
          element={<GoogleHealthConnect />}
        />
        <Route path="/api" element={<Api />} />
        <Route path="/connect" element={<VivantaConnect />} />
        <Route path="/webhooks" element={<Webhooks />} />
        <Route path="/parameters" element={<Parameters />} />
        <Route path="/activities" element={<Activities />} />
        <Route path="/intraday" element={<Intraday />} />
        <Route path="/embedded-graphs" element={<EmbeddedGraphs />} />
        <Route path="*" element={<h1>404 Not Found</h1>} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;

import { Fragment, useEffect } from "react";
import Navigation from "../Components/Navigation";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../Components/Footer";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { Link } from "react-router-dom";
import Sidebar from "../Components/Sidebar";

const Api = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <Navigation />
      <Container className="mt-4">
        <Row>
          <Col lg={2} md={3} sm={4}>
            <Sidebar current="api" />
          </Col>
          <Col lg={10} md={9} sm={8}>
            <Row>
              <Col className="documentation">
                <SwaggerUI url={"/api-json.json"} docExpansion="none" />
              </Col>
              <div className="d-flex justify-content-between mt-4">
                <Link to={"/connect"} className="btn btn-light">
                  &larr; Vivanta Connect
                </Link>
                <Link to={"/webhooks"} className="btn btn-light">
                  Webhooks &rarr;
                </Link>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </Fragment>
  );
};

export default Api;
